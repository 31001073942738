<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">
        用户协议
      </div>

      <el-button type="primary" @click="addEdit" size="small">保存</el-button>
    </div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content" style="width: 60vw">
        <UE ref="ue" :defaultMsg="ruleForm.content"></UE>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { UE } from "@/components";
import * as API_shop from "@/api/shop";
export default {
  components: {
    [UE.name]: UE,
  },
  name: "person",
  data () {
    return {
      rules: {
        name: { required: true, message: "请输入名称！", trigger: "blur" },
      },
      ruleForm: {
        content: '',
        name: "",
        type: 1
      },
    };
  },
  mounted () {
    this.getDetail();
  },

  created () { },
  computed: {},
  methods: {
    getDetail () {
      let par = {
        type: 1,
      };
      API_shop.getShopAgree(par).then((res) => {
        console.log(res);
        if (res) {
          this.ruleForm = res;
        }
      });
    },
    addEdit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let par = {
            ...this.ruleForm,
          };
          let content = this.$refs.ue.getUEContent();
          par.content = content;
          console.log(par, "par");
          API_shop.editAgree(par).then((res) => {
            console.log(res, "r");
            this.$message.success("保存成功！");
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner {
  width: 250px;
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 5px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

/deep/ .el-form-item__content {
  line-height: 0 !important;
}
</style>
